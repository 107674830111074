<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
          class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.couriers") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
          ></el-input>
        </div>
      </div>
      <div
          class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <el-button
            v-can="'partner_clinics.create'"
            size="mini"
            @click="drawerCreate = true"
            icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>
        <export-excel
            v-can="'organizations.excel'"
            class="btn excel_btn"
            :data="list"
            :fields="excel_fields"
            @fetch="controlExcelData()"
            worksheet="Курьер"
            name="Партнер_клиника.xls"
        >
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel>
        <crm-column-settings
            :columns="columns"
            :modelName="'couriers'"
            @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
      ></crm-pagination>
      <thead>
      <tr>
        <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :column="columns.phone_number"
            :sort="sort"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :column="columns.partner_clinic_id"
            :sort="sort"
            @change="updateSort"
        ></crm-th>
        <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
        ></crm-th>
      </tr>
      <tr>
        <th v-if="columns.id.show">
          <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
          ></el-input>
        </th>

        <th v-if="columns.name.show">
          <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
          >
          </el-input>
        </th>

        <th v-if="columns.phone_number.show">
          <el-input
              size="mini"
              v-model="filterForm.phone_number"
              :placeholder="columns.phone_number.title"
              clearable
          >
          </el-input>
        </th>

        <th>
          <el-select
              class="autoInput"
              v-model="filterForm.partner_clinic_id"
              filterable
              clearable
              :placeholder="$t('message.partner_clinic')"
          >
            <el-option
                v-for="item in partnerClinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </th>

        <th v-if="columns.updated_at.show">
          <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
          >
          </el-date-picker>
        </th>
        <th v-if="columns.created_at.show">
          <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
          >
          </el-date-picker>
        </th>
        <th v-if="columns.settings.show"></th>
      </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="courier in list" :key="courier.id">
          <td v-if="columns.id.show">{{ courier.id }}</td>
          <td v-if="columns.name.show">{{ courier.name }}</td>
          <td v-if="columns.phone_number.show">
            {{ courier.phone_number }}
          </td>
          <td>
            {{ courier.partner_clinic ? courier.partner_clinic.name : null }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ courier.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ courier.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
                :model="courier"
                name="partner_clinics"
                :actions="actions"
                @edit="edit"
                @delete="destroyModel"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
        :visible.sync="drawerCreate"
        size="50%" :wrapperClosable="false"
        :drawer="drawerCreate"
        @close="reloadIfChanged"
        class="mytab_IdP"
    >
      <div>
        <crm-create
            :reloadModel="reopenUpdate"
            @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
        :visible.sync="drawerUpdate"
        size="50%" :wrapperClosable="false"
        :drawer="drawerUpdate"
        @close="reloadIfChanged"
        class="mytab_IdP"
    >
      <div>
        <crm-update
            :reloadModel="reopenUpdate"
            @c-close="reloadIfChanged('drawerUpdate')"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import drawer from "@/utils/mixins/drawer";
import list from "@/utils/mixins/list";
export default {
  mixins: [list, drawer],
  name: "couriers",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      selectedModel: {},
      excel_fields: {},
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "couriers/list",
      columns: "couriers/columns",
      pagination: "couriers/pagination",
      filter: "couriers/filter",
      sort: "couriers/sort",
      partnerClinics: 'partnerClinic/inventory_all',
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    if (this.partnerClinics && this.partnerClinics.length === 0)
      this.loadPartnerClinic();
    this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "couriers/index",
      updateSort: "couriers/updateSort",
      updateFilter: "couriers/updateFilter",
      updateColumn: "couriers/updateColumn",
      updatePagination: "couriers/updatePagination",
      editModel: "couriers/show",
      empty: "couriers/empty",
      delete: "couriers/destroy",
      refreshData: "couriers/refreshData",
      loadPartnerClinic: "partnerClinic/inventoryAll",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
      }
    },
    refresh() {
      this.refreshData()
          .then((res) => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
          .then(async (res) => {
            this.selectedModel = model;
            this.drawerUpdate = true;
          })
          .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
          .then((res) => {
            this.drawerShow = true;
          })
          .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
          .then((res) => {
            this.$notify({
              title: 'Успешно',
              type: "success",
              offset: 130,
              message: res.message
            });
            this.fetchData();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
